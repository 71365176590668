import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import LoginLayout from "src/layouts/LoginLayout";
import DashboardLayout from "src/layouts/DashboardLayout/Index";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/LandingPage/Index")),
  },
  {
    exact: true,
    path: "/cricket",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/LandingPage/Index")),
  },
  {
    exact: true,
    path: "/football",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/LandingPage/Index")),
  },
  {
    exact: true,
    path: "/horse-racing",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/LandingPage/Index")),
  },
  {
    exact: true,
    path: "/e-sport",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/LandingPage/Index")),
  },
  {
    exact: true,
    path: "/fantasy",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/LandingPage/Index")),
  },
  {
    exact: true,
    path: "/predictions",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/LandingPage/Index")),
  },

  {
    exact: true,
    path: "/login",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/LoginPage/LoginPage")),
  },
  {
    exact: true,
    path: "/sign-up",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/SignUp/SgnUp")),
  },
  {
    exact: true,
    path: "/forgetpassword",
    layout: LoginLayout,
    component: lazy(() =>
      import("src/views/pages/Auth/ForgotPassword/Forgetpassword")
    ),
  },
  {
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() =>
      import("src/views/pages/Auth/ResetPassword/ResetPassword")
    ),
  },
  {
    guard: true,
    exact: true,
    path: "/user",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Admin/User/TabUser")),
  },
  {
    guard: true,
    exact: true,
    path: "/viewpost",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Admin/Dasboard/ViewPost")),
  },
  {
    guard: true,
    exact: true,
    path: "/addmanagement",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Admin/AddManagement/index")),
  },
  {
    guard: true,
    exact: true,
    path: "/skyscraper",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Admin/AddManagement/AddSky")),
  },
  {
    guard: true,
    exact: true,
    path: "/MREC",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Admin/AddManagement/AddMfrc")
    ),
  },
  {
    guard: true,
    exact: true,
    path: "/popup",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Admin/AddManagement/AddPopup")
    ),
  },
  {
    guard: true,
    exact: true,
    path: "/dashboard",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Admin/Dasboard/Index")),
  },
  {
    guard: true,
    exact: true,
    path: "/post-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Admin/Dasboard/Index")),
  },
  {
    guard: true,
    exact: true,
    path: "/view-user",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Admin/User/ViewProfile")),
  },
  {
    path: "/verify-otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/VarifyOtp/index")),
  },

  {
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() =>
      import("src/views/pages/Auth/ResetPassword/ResetPassword")
    ),
  },
  {
    guard: true,
    exact: true,
    path: "/addnew",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Admin/AddManagement/Addnew")),
  },
  {
    guard: true,
    exact: true,
    path: "/postblog-image",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Admin/Dasboard/PostBlogImage")),
  },
  {
    guard: true,
    exact: true,
    path: "/postblog-video",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Admin/Dasboard/PostBlogVideo")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    exact: true,
    path: "/score-details",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Score/ScoreDetail")),
  },
  {
    exact: true,
    path: "/viewlinks",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Admin/SocialManagment/ViewSocialLink")
    ),
  },
  {
    exact: true,
    path: "/news-details",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/Home/NewsDetails/NewsDetails")
    ),
  },
  {
    exact: true,
    path: "/privacy",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Static/Privacy")),
  },
  {
    exact: true,
    path: "/disclaimer",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Static/Disclaimer")),
  },
  {
    exact: true,
    path: "/terms",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Static/Terms")),
  },
  {
    exact: true,
    path: "/about",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Static/About")),
  },

  {
    exact: true,
    path: "/contact",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Static/Contact")),
  },

  {
    exact: true,
    path: "/feedback",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Static/Feedback")),
  },
  {
    guard: true,
    exact: true,
    path: "/profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Admin/ProfileEdit")),
  },
  {
    // guard: true,
    exact: true,
    path: "/user-profile",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Admin/ProfileEdit")),
  },
  {
    // guard: true,
    exact: true,
    path: "/social",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Admin/SocialManagment/Index")
    ),
  },
  {
    // guard: true
    exact: true,
    path: "/static",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Admin/StaticManagement/Index")
    ),
  },
  {
    // guard: true
    exact: true,
    path: "/editstatic",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Admin/StaticManagement/EditStaticData")
    ),
  },
  {
    // guard: true
    exact: true,
    path: "/view-contact",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Admin/ContactManagement/ViewContact")
    ),
  },
  {
    // guard: true
    exact: true,
    path: "/contact-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Admin/ContactManagement/Index")
    ),
  },
  {
    // guard: true
    exact: true,
    path: "/feedback-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Admin/FeedbackManagement/Index")
    ),
  },
  {
    // guard: true
    exact: true,
    path: "/user-profile-view",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/Admin/ProfileEdit")
    ),
  },
  {
    // guard: true
    exact: true,
    path: "/view-feedback",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Admin/FeedbackManagement/ViewFeedback")
    ),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
