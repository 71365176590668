import React, { useContext } from "react";
import SettingsContext from "src/context/SettingsContext";



const Logo = (props) => {
  const themeSeeting = useContext(SettingsContext);


  console.log("themeSeeting----", themeSeeting.settings.theme)

  return (
    <>
      <div>
        {themeSeeting.settings.theme === "DARK" ? (
          <img
            className="mobileShow"
            src="/images/DarkLogo.png"
            alt="Logo"
            {...props}
          />
        ) : (
          <img
            className="mobileShow"
            src="/images/LightLogo.png"
            alt="Logo"
            {...props}
          />
        )}
      </div>
      <div>
        {themeSeeting.settings.theme === "DARK" ? (
          <img
            className="mobileHide"
            src="/images/DarkLogo.png"
            alt="Logo"
            {...props}
          />
        ) : (
          <img
            className="mobileHide"
            src="/images/LightLogo.png"
            alt="Logo"
            {...props}
          />
        )}
      </div>
    </>
  );
};

export default Logo;
