import React ,{ useContext}from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  Button,
  ListItem,
  List,
  Link,
  IconButton,
} from "@material-ui/core";
import { AuthContext } from "src/context/Auth";
import { useHistory, Link as RouterLink } from "react-router-dom";
import Scroll from "react-scroll";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
const ScrollLink = Scroll.Link;

const useStyles = makeStyles((theme) => ({
  footerSection: {
    overflow: "hidden",
    background: "#2E2D30",
    position: "relative",
    padding: "30px 0px",
    zIndex: "2",
    "& .displayColumn": {
      [theme.breakpoints.only("xs")]: {
        flexDirection: "column!important",
      },
    },
    "& .bottomText": {
      [theme.breakpoints.only("xs")]: {
        justifyContent: "center",
      },
    },

    "& .responsiveText": {
      [theme.breakpoints.only("xs")]: {
        marginTop: "20px",
      },
    },
    "& .coprightBox": {
      [theme.breakpoints.only("xs")]: {
        marginTop: "-14px",
        marginBottom: "12px",
      },
    },

    "& .clipborder": {
      marginLeft: "10px",
    },

    "& p": {
      [theme.breakpoints.down("xs")]: {
        // fontSize: "12px !important",
        maxWidth: "100%",
      },
    },
    "& a": {
      color: "#fff",
      display: "flex",
      fontSize: "13px",
      alignItems: "center",
      fontWeight: "300",
      textDecoration: "none",
      lineHeight: "28px",
      cursor: "pointer",
      // textTransform: "uppercase",
      "&:hover": {
        color: "#0E52D5",
        textDecoration: "none",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
        lineHeight: "10px",
      },
    },
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  const history = useHistory();
  const auth = useContext(AuthContext);

  return (
    <>
      <Box className={classes.footerSection}>
        <Container maxWidth="lg">
          <Box className="displaySpacebetween displayColumn">
            <Box className="displayStart">
              <RouterLink to="/">
                <img src="images/DarkLogo.png" style={{ maxWidth: "150px" }} />{" "}
              </RouterLink>
            </Box>
            <Box className="displayStart responsiveText">
              <Typography
                variant="body1"
                className="clipborder"
                to="/about"
                component={RouterLink}
              >
                ABOUT
              </Typography>
              <Typography
                variant="body1"
                className="clipborder"
                to="/contact"
                component={RouterLink}
              >
                CONTACT
              </Typography>
              {auth?.userData?.userType === "USER" ? (
                <Typography
                  variant="body1"
                  className="clipborder"
                  to="/feedback"
                  component={RouterLink}
                >
                  FEEDBACK
                </Typography>
              ) : (
                " "
              )}

              {/* <Typography variant="body1" className="clipborder" to="/news-details" component={RouterLink}>
                                CAREERS

                            </Typography> */}
              <Typography
                variant="body1"
                className="clipborder"
                to="/disclaimer"
                component={RouterLink}
              >
                DISCLAIMER
              </Typography>
            </Box>
          </Box>

          <Box className="displaySpacebetween displayColumn" mt={3}>
            <Box className="displayStart coprightBox">
              <Typography variant="body1" className="bottomText">
                2022 © 12thkhiladi Group Limited Company. All Rights Reserved
              </Typography>
            </Box>
            <Box className="displayStart">
              <IconButton
                className="socialIconBox"
                onClick={() => window.open(" https://www.facebook.com/")}
              >
                <FaFacebookF />
              </IconButton>
              <IconButton
                className="socialIconBox"
                onClick={() => window.open(" https://twitter.com/")}
              >
                <FaTwitter />
              </IconButton>
              <IconButton
                className="socialIconBox"
                onClick={() => window.open(" https://www.instagram.com/")}
              >
                <FaInstagram />
              </IconButton>
            </Box>
          </Box>
          <Box className="displayEnd bottomText" align="right" mt={2}>
            <Typography
              variant="body1"
              className="clipborder"
              to="/terms"
              component={RouterLink}
            >
              Terms and Conditions
            </Typography>
            <Typography
              variant="body1"
              className="clipborder"
              to="/privacy"
              component={RouterLink}
            >
              Privacy Policy
            </Typography>
          </Box>
        </Container>
      </Box>
    </>
  );
}
