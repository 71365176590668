// --staging
export const baseurl = "https://node-12thkhiladi.mobiloitte.com/";
export const socketURL = "wss://node-12thkhiladi.mobiloitte.com";

// export const baseurl = "http://172.16.1.172:1954/"; // local
// export const socketURL = "ws://172.16.1.172:1954"; //local

// export const baseurl = "http://172.16.2.248:1954/";
// export const socketURL = "ws://172.16.2.248:1954"; //local Ashu sir

let base = `${baseurl}api/v1`;
let user = `${base}/user`;
let admin = `${base}/admin`;
let feed = `${base}/feed`;
let cricket = `${base}/cricket`;
let advertisement = `${base}/advertisement`;
let static1 = `${base}/static`;

let statics = `${base}/statics`;

let staticLink = `${base}/staticLink`;
let contact1 = `${base}/contactUs`;

const Apiconfigs = {
  login: `${user}/login`,
  register: `${user}/register`,
  verifyOTP: `${user}/verifyOTP`,
  resendOTP: `${user}/resendOTP`,
  forgotPassword: `${user}/forgotPassword`,
  profile: `${user}/profile`,
  editProfile: `${user}/editProfile`,
  resetPassword: `${user}/resetPassword`,
  changePassword: `${user}/changePassword`,
  uploadImage: `${user}/uploadImage`,
  uploadMultipleImage: `${user}/uploadMultipleImage`,
  getAllfeeds: `${user}/getAllfeeds`,
  searchFeed: `${user}/searchFeed`,
  socialLogin: `${user}/socialLogin`,

  // admin
  dashboard: `${admin}/dashboard`,
  listUser: `${admin}/listUser`,
  blockUnblockUser: `${admin}/blockUnblockUser`,
  deleteUser: `${admin}/deleteUser`,
  setUserType: `${admin}/setUserType`,
  viewUser: `${admin}/viewUser`,
  blockUnblockUser: `${admin}/blockUnblockUser`,
  removeUserType: `${admin}/removeUserType`,

  // listUser: `${admin}/listUser`,

  // dashboards
  feed: `${base}/feed/feed`,
  feedNews: `${base}/feedNews/feedNews`,
  feedPodcast: `${base}/feedPodcast/feedPodcast`,
  feedVideo: `${base}/feedVideo/feedVideo`,
  googleAdd: `${base}/googleAdd/googleAdd`,
  gameCategoryList: `${base}/category/gameCategoryList`,
  faq: `${base}/faq/faq`,
  staticLink: `${base}/staticLink/staticLink`,
  
  // advertisment
  enableDisableAdd: `${advertisement}/enableDisableAdd`,
  advertisement: `${advertisement}/advertisement`,
  advertisementId: `${advertisement}/advertisement/`,

  //cricket
  scheduledList: `${cricket}/scheduledList/`,

  //STATIC
  staticContentList: `${static1}/static`,

  //Social
  staticLinkContentList: `${staticLink}/staticLink`,

  //feed
  feedidlist: `${feed}/feed`,
  feedView: `${feed}/feed/`,
  enableFeed: `${feed}/enableFeed`,
  disableFeed: `${feed}/disableFeed`,

  // >>>>>>> 6f3cc35219581ac0a35badf2d64bace5e6e0c82f
  staticContenttype: `${static1}/static/`,

  //Social
  staticLinkContentList: `${staticLink}/staticLink`,
  staticLinkContentId: `${staticLink}/staticLink/`,

  //feedback
  listFeedback: `${contact1}/listFeedback`,
  addFeedback: `${contact1}/addFeedback`,
  viewFeedback: `${contact1}/viewFeedback`,

  // ContactUs
  listContactUs: `${contact1}/contactUs`,

// ****************************** NEW CHANGES *************************************
  
// FEED_MANAGEMENT_BLOG
  feedblogList: `${base}/feed/feedblogList`,
  feedViewBlogs: `${feed}/viewFeedBlog`,
  updatefeedBlog: `${feed}/updatefeedBlog`,
  deleteFeedBlog: `${feed}/deleteFeedBlog`,

  feedNewsList: `${feed}/feedNewsList`,



  // FEED_MANAGEMENT_VIDEO
  feedVideoList: `${base}/feedVideo/feedVideoList`,
  
  
  addfeedVideo: `${base}/feedVideo/addfeedVideo`,
  viewFeedVideo: `${base}/feedVideo/viewFeedVideo/`,

 


  // ADVERTISEMENT_MANAGEMENT
  advertisementList: `${advertisement}/advertisementList`,
  addAdvertisement: `${advertisement}/addAdvertisement`,
  viewaAdvertisement: `${advertisement}/viewaAdvertisement/`,
  updateAdvertisement: `${advertisement}/updateAdvertisement`,
  deleteAdvertisement: `${advertisement}/deleteAdvertisement`,


  
  //Static
  static: `${statics}/static`,
 


  
};
export default Apiconfigs;
