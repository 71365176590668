import React from "react";
import Loader from "react-js-loader";
export default function DataLoader() {
  return (
    <div>
      <Loader
        type="bubble-spin"
        bgColor={"#0E52D5"}
        title={"Loading..."}
        color={"#0E52D5"}
        size={60}
      />
    </div>
  );
}
