import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Typography,
  IconButton,
  Box,
  Container,
} from "@material-ui/core";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { CompassCalibrationSharp } from "@material-ui/icons";
import axios from "axios";
import { getWithoutDataHandlerAPI } from "src/apiConfig/service";
import Apiconfigs from "src/apiConfig/config";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  topheaderBox: {
    backgroundColor: "#0E52D5",
    padding: "8px",
    "& .socialEndEnd": {
      "& .MuiIconButton-root": {
        padding: "0px",
      },
    },
  },
}));

function TopHeaderSection() {
  const classes = useStyles();
  const location = useLocation();
  const [socialLink, setSocialLink] = useState([]);
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    var timer = setInterval(() => setDate(new Date()), 1000);
    return () => {
      clearInterval(timer);
    };
  }, [date]);

  const myCurrentYear = date.getFullYear();
  const myCurrentDate = date.getDate();
  const myCurrentMonth = date.getMonth();
  const myCurrentTime = date.toLocaleTimeString();

  const getSocialLinkHandler = async (values) => {
    try {
      const response = await getWithoutDataHandlerAPI("staticLinkContentList");
      if (
        response?.data !== undefined &&
        response?.data?.statusCode !== undefined
      ) {
        if (response?.data?.statusCode == 200) {
          setSocialLink(response?.data?.result);
        }
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getSocialLinkHandler();
  }, []);



  return (
    <Box className={classes.topheaderBox}>
      <Container maxWidth="lg">
        <Box className="socialEndEnd">
          <Typography variant="body1" style={{ marginRight: "40px" }}>
            {moment().format("LLLL")}
          </Typography>

          <a
            href={socialLink?.find((data) => data.link == "facebooklink")?.url}
            target="_blank"
          >
            <IconButton className="socialIconBox">
              <FaFacebookF />
            </IconButton>
          </a>
          <a
            href={socialLink?.find((data) => data.link == "twitterlink")?.url}
            target="_blank"
          >
            <IconButton className="socialIconBox">
              <FaTwitter />
            </IconButton>
          </a>
          <a
            href={socialLink?.find((data) => data.link == "instagramlink")?.url}
            target="_blank"
          >
            <IconButton className="socialIconBox">
              <FaInstagram />
            </IconButton>
          </a>
        </Box>
      </Container>
    </Box>
  );
}

export default TopHeaderSection;
