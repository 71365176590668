/* eslint-disable no-use-before-define */
import React, { useEffect, useContext } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Logo from "src/component/Logo";
import {
  FaTachometerAlt,
  FaUniversity,
  FaWallet,
  FaClipboardCheck,
  FaMoneyBillAlt,
  FaPhoneSquareAlt,
  FaGooglePlusG,
} from "react-icons/fa";
import { HiDocumentText } from "react-icons/hi";
import { RiAdvertisementFill } from "react-icons/ri";
import { IoLogOutOutline } from "react-icons/io5";
import { MdLogout } from "react-icons/md";
import { GiToken, GiTrade } from "react-icons/gi";
import NavItem from "./NavItem";
import PeopleIcon from "@material-ui/icons/People";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PersonIcon from "@material-ui/icons/Person";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import DevicesOtherIcon from "@material-ui/icons/DevicesOther";
import RecentActorsIcon from "@material-ui/icons/RecentActors";
import SubscriptionsIcon from "@material-ui/icons/Subscriptions";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import { AuthContext } from "src/context/Auth";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { BsFilePost } from "react-icons/bs";
import { IoShareSocialSharp } from "react-icons/io5";
import { MdOutlineContentPaste } from "react-icons/md";
import { BsTelephoneFill } from "react-icons/bs";
import { MdFeedback } from "react-icons/md";
const sectionsEDITOR = [
  {
    items: [
      {
        title: "Home",
        icon: FaTachometerAlt,
        href: "/",
      },
      {
        title: "Dashboard",
        icon: DashboardIcon,
        href: "/dashboard",
      },
      {
        title: "Post Management",
        icon: BsFilePost,
        href: "/post-management",
      },
      // {
      //   title: "User Management",
      //   icon: PeopleIcon,
      //   href: "/user",
      // },
      // {
      //   title: "Ads Management",
      //   icon: RiAdvertisementFill,
      //   href: "/addmanagement",
      // },
    ],
  },
];
const sections = [
  {
    items: [
      {
        title: "Home",
        icon: FaTachometerAlt,
        href: "/",
      },
      {
        title: "Dashboard",
        icon: DashboardIcon,
        href: "/dashboard",
      },
      {
        title: "Post Management",
        icon: BsFilePost,
        href: "/post-management",
      },
      {
        title: "User Management",
        icon: PeopleIcon,
        href: "/user",
      },
      {
        title: "Ads Management",
        icon: RiAdvertisementFill,
        href: "/addmanagement",
      },
      {
        title: "Static Management",
        icon: MdOutlineContentPaste,
        href: "/static",
      },
      {
        title: "Social Management",
        icon: IoShareSocialSharp,
        href: "/social",
      },
      {
        title: "Contact Management",
        icon: BsTelephoneFill,
        href: "/contact-management",
      },
      {
        title: "Feedback Management",
        icon: MdFeedback,
        href: "/feedback-management",
      },
    ],
  },
];
const sectionsBelow = [
  {
    items: [
      {
        // title: "Logout",
        icon: ExitToAppIcon,
        href: "/terms-and-condition",
      },
      // {
      //   title: "Privacy Policy",
      //   //icon: PieChartIcon,
      //   href: "/privacy-policy",
      // },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 220,
    backgroundColor: theme.palette.background.default,
  },
  desktopDrawer: {
    width: 220,
    top: 0,
    height: "100%",

    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  ButtonBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "10px",
  },
  logoicon: {
    display: "flex",
    marginTop: "16px",
    alignItems: "center",
    marginLeft: "30px",
  },
  logoutbutton: {
    // justifyContent: "space-between",
    paddingLeft: 10,
    borderRadius: 10,
    width: "100px",
    textAlign: "center",
    color: "#5089F6",
    border: "1px solid #474747",
    textAlign: "center",
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const [open, setOpen] = React.useState(false);
  const auth = useContext(AuthContext);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const logoutHandler = () => {
    history.push("/");
    auth.userLogIn(false, null);
    window.sessionStorage.removeItem("token");
    auth.getUserProfileDatahandler(window.sessionStorage.removeItem("token"));
  };
  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      {/* <Hidden mdDown> */}
      <Box
        padding={0}
        onClick={() => history.push("/")}
        className={classes.logoicon}
      >
        <Logo
          width="180"
          style={{
            width: "125px",

            cursor: "pointer",
          }}
        />
      </Box>
      {/* </Hidden> */}
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        {/* {auth?.userData?.userType == "EDITOR" &&
       ( <Box py={2}>
          {sectionsEDITOR.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>)}
       { auth?.userData?.userType == "SUB_ADMIN"&& ( */}
        <Box py={2}>
          {sections.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
        {/* )
        }
       {auth?.userData?.userType == "ADMIN" && ( <Box py={2}>
          {sections.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>)
        } */}
        <Box className="side_nev_Bottom">
          {sectionsBelow.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {section.items.map((itemList, i) => {
                return (
                  <Box textAlign="center">
                    <Button
                      fullWidth
                      color="primary"
                      key={i}
                      className={classes.logoutbutton}
                      style={{}}
                      onClick={handleClickOpen}
                    >
                      Logout
                    </Button>

                    <Dialog
                      open={open}
                      fullWidth
                      maxWidth="xs"
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <Box py={2}>
                        <DialogTitle id="alert-dialog-title">
                          <Box align="center" mt={1}>
                            <Typography variant="h6">LOGOUT</Typography>
                          </Box>
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText
                            id="alert-dialog-description"
                            align="center"
                          >
                            <Box align="center">
                              <Typography variant="h6">
                                Are you sure you want to logout?
                              </Typography>
                            </Box>
                          </DialogContentText>
                        </DialogContent>

                        <Box align="center" className={classes.ButtonBox}>
                          <Button
                            onClick={handleClose}
                            variant="contained"
                            color="primary"
                            autoFocus
                          >
                            No
                          </Button>
                          &nbsp;&nbsp;
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={logoutHandler}
                          >
                            Yes
                          </Button>
                        </Box>
                      </Box>
                    </Dialog>
                  </Box>
                );
              })}

              {/* {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })} */}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
