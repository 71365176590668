import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Box,
  TextField,
  FormControl,
  DialogContent,
  Container,
  InputAdornment,
  DialogContentText,
  Typography,
  Avatar,
  Dialog,
  Paper,
} from "@material-ui/core";
import Logo from "src/component/Logo";
import MenuIcon from "@material-ui/icons/Menu";
import HomeIcon from "@material-ui/icons/Home";
import SearchIcon from "@material-ui/icons/Search";
import SettingsContext from "src/context/SettingsContext";
import { NavLink } from "react-router-dom";
import { BsCircleHalf, BsSearch } from "react-icons/bs";
import { CgCloseO } from "react-icons/cg";
import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import { AuthContext } from "src/context/Auth";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import DialogTitle from "@material-ui/core/DialogTitle";
import SearchResultFound from "src/component/SearchResultFound";
import RndomAddImageModal from "src/component/RndomAddImageModal";
import RandomLoginAlrtModal from "src/component/RandomLoginAlrtModal";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    fontSize: "12px",
    fontWeight: "500",
    borderRadius: 0,
    minWidth: "auto",
    color: theme.palette.primary.default,
    padding: "0px 14px",
    letterSpacing: "1px",
    marginLeft: "15px",
    textDecoration: "none",
    lineHeight: "54px",
    display: "flex",

    "& span": {
      display: "flex",
      alignItems: "center",
    },
    "@media (max-width: 1280px)": {
      fontStyle: "normal",
      letterSpacing: "1px",
      lineHeight: "7px",
      color: theme.palette.primary.main,
      padding: "12px 0px !important",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      height: "auto",
    },
    "&.activeMenu": {
      color: "#0E52D5",
      position: "relative",
      "&:before": {
        content: "' '",
        position: "absolute",
        top: "62px",
        left: " 0px",
        width: "100%",
        height: "5px",
        background: "#0E52D5",
        [theme.breakpoints.down("md")]: {
          display: "none",
        },
      },
    },

    "&:hover": {
      color: "#0E52D5",
    },
  },
  menuButton1: {
    fontSize: "12px",
    fontWeight: "500",
    borderRadius: 0,
    minWidth: "auto",
    display: "flex",
    color: theme.palette.primary.default,
    padding: "14px",
    marginLeft: "15px",
    "& span": {
      display: "flex",
      alignItems: "center",
    },
    "@media (max-width: 1280px)": {
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "24px",
      color: "#000",
      width: "100%",
      padding: "0px",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      height: "auto",
    },
    "&.activeMenu": {
      color: "#0E52D5",
      position: "relative",
      "&:before": {
        content: "' '",
        position: "absolute",
        top: "62px",
        left: " 0px",
        width: "100%",
        height: "5px",
        background: "#0E52D5",
        [theme.breakpoints.down("md")]: {
          display: "none",
        },
      },
    },
  },

  menuButton3: {
    fontSize: "13px",
    fontWeight: "500",
    color: theme.palette.primary.main,
    padding: "0px 14px",
    letterSpacing: "1px",
    marginLeft: "11px",
    textDecoration: "none",
    lineHeight: "43px",
    display: "flex",
    [theme.breakpoints.down("md")]: {
      marginLeft: "24px",
    },
    "@media (max-width: 1280px)": {
      fontStyle: "normal",
      letterSpacing: "1px",
      lineHeight: "7px",
      color: theme.palette.primary.main,
      padding: "12px 0px !important",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      height: "auto",
    },
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },

  menuButton2: {
    fontSize: "13px",
    fontWeight: "500",
    borderRadius: 0,
    minWidth: "auto",
    color: theme.palette.primary.main,
    padding: "0px 14px",
    letterSpacing: "1px",
    marginLeft: "11px",
    textDecoration: "none",
    lineHeight: "30px",
    display: "flex",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      marginLeft: "24px",
    },
    "& span": {
      display: "flex",
      alignItems: "center",
    },
    "@media (max-width: 1280px)": {
      fontStyle: "normal",
      letterSpacing: "1px",
      lineHeight: "7px",
      color: theme.palette.primary.main,
      padding: "12px 0px !important",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      height: "auto",
    },
    "&.activeMenu": {
      color: "#0E52D5",
      position: "relative",
      // "&:before": {
      //   content: "' '",
      //   position: "absolute",
      //   top: "47px",
      //   left: " 0px",
      //   width: "100%",
      //   height: "5px",
      //   background: "#0E52D5",
      //   [theme.breakpoints.down("md")]: {
      //     display: "none",
      //   },
      // },
    },
    "&:hover": {
      color: " #0E52D5",
    },
  },
  toolbar: {
    display: "flex",
    padding: "8px 0",
    "@media (max-width: 900px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
    },
  },
  logoDrawer: {
    paddingLeft: "21px",
    width: "200px",
    marginBottom: "15px",
  },
  drawerContainer: {
    padding: "20px 0px ",
    height: "100%",
    width: "300px",
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    overflow: "scroll",
    overflowX: "hidden",
  },
  drawericon: {
    color: "#000",
    fontSize: "25px",
  },
  containerHeight: {
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
    minHeight: "60px !important",
  },
  inerrMenu: {
    display: "flex",
    listStyle: "none",
    padding: "0",
    whiteSpace: "nowrap",
    paddingLeft: "25px",
    // alignItems: "center",
    justifyContent: "flex-start",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      paddingLeft: "5px",
    },
    "& li": {
      "&.active": {
        background: "red",
      },
    },
  },
  inerrMenu1: {
    display: "flex",
    listStyle: "none",
    padding: "0",
    whiteSpace: "nowrap",
    // paddingLeft: "25px",
    // alignItems: "center",

    justifyContent: "flex-start",
    flexDirection: "column",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      display: "block",
      padding: "0",
    },
    "& li": {
      "&.active": {
        background: "red",
      },
    },
  },
  menuul: {
    display: "flex",
    listStyle: "none",
    padding: "0",
    alignItems: "center",
    height: "30px",
    justifyContent: "space-between",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      display: "block",
      "& .logoDesk": {
        display: "none",
      },
    },
  },
  menuul1: {
    display: "block",
    display: "flex",
    listStyle: "none",
    padding: "0",
    alignItems: "center",
    height: "30px",
    justifyContent: "space-between",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      display: "block",
      "& .logoDesk": {
        display: "none",
      },
    },
  },
  mainBox: {
    "& .heading": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: "10px",
    },
  },
  moblesectionlogo: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "20px",
    position: "relative",
    "& .iconsection": {
      top: "16px",
      right: "13px",
      position: "absolute",
      "& svg": {
        fontSize: "24px",
        color: "#50555d",
      },
    },
  },
  avatarsection: {
    width: "100%",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    marginTop: "30px",
    marginLeft: "24px",
    "& h5": {
      color: theme.palette.primary.main,
    },
    "& avatar": {
      width: "72px",
      height: "72px",
      borderRadius: "50%",
    },
  },
  marginsec: {
    marginTop: "255px",
    "@media(max-width:1280px)": {
      marginTop: "155px",
      marginLeft: "-5px",
    },
  },
  dialogPaper: {
    position: "absolute",
    top: "30px",
  },
  ButtonBox: { paddingBottom: "20px" },
  dessection: {
    marginTop: "110px",
    "@media(max-width:1281px)": {
      marginTop: "0px",
    },
  },
  menuButtonBox: {
    marginLeft: "-8px",
  },
  DialogBox: {
    padding: "8px 24px",
    minHeight: "calc(100vh - 100px)",
    ".MuiDialogContent-root": {
      minHeight: "669px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "8px 0px",
    },
  },
  menuIcon: {
    "& .MuiIconButton-root": {
      padding: "8px 0px 8px 8px",
    },
  },
}));
export default function Header({ buttonClick }) {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [searchUserName, setSearchUserName] = useState();
  const auth = useContext(AuthContext);
  const [open, setOpen] = React.useState(false);

  const [openRandomAddImage, setOpenRandomAddImage] = useState(false);
  const [openLoginAlrtModal, setOpenLoginAlrtModal] = useState(false);

  const OpenRandomAddImageHandler = () => {
    setOpenRandomAddImage(true);
  };

  const CloseRandomAddImage = () => {
    setOpenRandomAddImage(false);
  };
  const OpenLoginAlrtModalHamdler = () => {
    setOpenLoginAlrtModal(true);
  };
  const CloseLoginAlrtModal = () => {
    setOpenLoginAlrtModal(false);
  };

  const {
    toolbar,
    menuul,
    menuul1,
    dialogPaper,
    drawerContainer,
    drawericon,
    logoDrawer,
    containerHeight,
    inerrMenu,
    inerrMenu1,

    mainHeader,
    menuButton,
    menuButton1,
    menuButton2,
    menuButton3,
    menuButtonBox,
  } = useStyles();
  const history = useHistory();
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const { mobileView, drawerOpen } = state;
  const themeSeeting = useContext(SettingsContext);
  const changeTheme = (type) => {
    themeSeeting.saveSettings({
      theme: type,
    });
  };

  const handleDrawerOpen = () =>
    setState((prevState) => ({ ...prevState, drawerOpen: true }));
  const handleDrawerClose = () =>
    setState((prevState) => ({ ...prevState, drawerOpen: false }));
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 960
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
  }, []);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const logoutHandler = () => {
    history.push("/");
    auth.userLogIn(false, null);
    window.sessionStorage.removeItem("token");

    auth.getUserProfileDatahandler();
    handleClose();
  };
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setOpenRandomAddImage(true);
  //   }, 1500);
  //   return () => clearTimeout(timer);
  // });
  // useEffect(() => {
  //   if (pageURL) {
  //     let id = pageURL.split("/")[pageURL.split("/")?.length - 1];
  //     let categoryType = "";
  //     if (id == "cricket") {
  //       categoryType = "CRICKET";
  //     }
  //     if (id == "football") {
  //       categoryType = "FOOTBALL";
  //     }
  //     // if(id == "cricket"){
  //     //   categoryType = "FANTACY"
  //     // }
  //     if (id == "e-sport") {
  //       categoryType = "E_SPORTS";
  //     }
  //     if (id == "horse-racing") {
  //       categoryType = "HORSE_RACING";
  //     }
  //     if (id == "fantasy-predictions") {
  //       categoryType = "PREDICTION";
  //     }
  // console.log(" pageURL ---------------- pageURL ", mobileView);
  //     auth.setcategoryType(categoryType);
  //   }
  // }, [pageURL]);

  const displayDesktop = () => {
    return (
      <Box maxWidth="lg">
        <Toolbar className={toolbar}>
          {productLogo}
          {menuText}
        </Toolbar>
      </Box>
    );
  };
  const displayMobile = () => {
    return (
      <Toolbar className={mainHeader}>
        <Box style={{ display: "flex" }}>
          <Box>{productLogo}</Box>
        </Box>
      </Toolbar>
    );
  };
  const productLogo = (
    <Box display="flex" alignItems="center">
      <Link to="/" style={{ display: "inherit" }}>
        <Logo />
      </Link>
    </Box>
  );
  const menuText = (
    <nav>
      <ul class={menuul}>
        <li className="activecolor">
          <ul className={inerrMenu}>
            {mobileView ? (
              ""
            ) : (
              <li>
                <NavLink
                  exact
                  activeClassName={mobileView ? "" : "activeMenu"}
                  className={menuButton1}
                  to="/"
                  onClick={() => auth.setcategoryType("")}
                >
                  {" "}
                  <HomeIcon />
                </NavLink>
              </li>
            )}
            <li>
              <NavLink
                exact
                activeClassName={mobileView ? "" : "activeMenu"}
                className={menuButton}
                to="/cricket"
                onClick={() => auth.setcategoryType("CRICKET")}
              >
                {" "}
                CRICKET{" "}
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                activeClassName={mobileView ? "" : "activeMenu"}
                className={menuButton}
                to="/football"
                onClick={() => auth.setcategoryType("FOOTBALL")}
              >
                {" "}
                FOOTBALL{" "}
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                activeClassName={mobileView ? "" : "activeMenu"}
                className={menuButton}
                to="/horse-racing"
                onClick={() => auth.setcategoryType("HORSE_RACING")}
              >
                {" "}
                HORSE RACING
              </NavLink>{" "}
            </li>
            <li>
              <NavLink
                exact
                activeClassName={mobileView ? "" : "activeMenu"}
                className={menuButton}
                to="/e-sport"
                onClick={() => auth.setcategoryType("E_SPORTS")}
              >
                {" "}
                ESPORT
              </NavLink>{" "}
            </li>
            <li>
              <NavLink
                exact
                activeClassName={mobileView ? "" : "activeMenu"}
                className={menuButton}
                to="/predictions"
                onClick={() => auth.setcategoryType("PREDICTION")}
              >
                {" "}
                PREDICTIONS
              </NavLink>{" "}
            </li>
            <li>
              <NavLink
                exact
                activeClassName={mobileView ? "" : "activeMenu"}
                className={menuButton}
                to="/fantasy"
                onClick={() => auth.setcategoryType("FANTACY")}
              >
                {" "}
                FANTASY
              </NavLink>{" "}
            </li>
            {!auth?.userLoggedIn || auth?.userData?.userType === "USER" ? (
              ""
            ) : (
              <li>
                <NavLink
                  exact
                  activeClassName={mobileView ? "" : "activeMenu"}
                  className={menuButton}
                  to="/dashboard"
                >
                  {" "}
                  DASHBOARD
                </NavLink>{" "}
              </li>
            )}
          </ul>
        </li>
      </ul>
    </nav>
  );
  const destopmenuText = (
    <Box>
      <ul class={menuul1}>
        <li className="activecolor">
          <ul className={inerrMenu1}>
            {/* <Box className={menuButton3} style={{ fontSize: "16px" , }}>
              MORE LINKS
            </Box> */}

            <li>
              <NavLink exact className={menuButton2} to="/about">
                {" "}
                About Us{" "}
              </NavLink>
            </li>
            <li>
              <NavLink exact className={menuButton2} to="/contact">
                {" "}
                Contact Us{" "}
              </NavLink>
            </li>
            <li>
              {/* <NavLink exact className={menuButton2} to="/news-details">
                {" "}
                Carrers{" "}
              </NavLink> */}
            </li>
            <li>
              <NavLink exact className={menuButton2} to="/">
                {" "}
                Advertise with us{" "}
              </NavLink>
            </li>
            <li>
              <NavLink exact className={menuButton2} to="/privacy">
                {" "}
                Privacy Policy{" "}
              </NavLink>
            </li>
            <Box textAlign="center">
              {auth?.userLoggedIn ? (
                <Box
                  // fullWidth
                  // color="primary"
                  // className={classes.logoutbutton}
                  className={menuButton2}
                  onClick={handleClickOpen}
                >
                  Logout
                </Box>
              ) : (
                ""
              )}

              <Dialog
                open={open}
                fullWidth
                maxWidth="xs"
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <Box py={2}>
                  <DialogTitle id="alert-dialog-title">
                    <Box align="center">
                      <Typography variant="h6">LOGOUT</Typography>
                    </Box>
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText
                      id="alert-dialog-description"
                      align="center"
                    >
                      <Box align="center">
                        <Typography variant="h6">
                          Are you sure you want to logout?
                        </Typography>
                      </Box>
                    </DialogContentText>
                  </DialogContent>

                  <Box align="center" className={classes.ButtonBox}>
                    <Button
                      onClick={handleClose}
                      variant="contained"
                      color="primary"
                      autoFocus
                    >
                      No
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={logoutHandler}
                    >
                      Yes
                    </Button>
                  </Box>
                </Box>
              </Dialog>
            </Box>
            <Box
              className={menuButton3}
              style={{ fontSize: "16px", fontWeight: "bold" }}
              mt={2}
            >
              FOLLOW US
            </Box>
            <Box className={menuButtonBox}>
              <Box className={menuButton2} mt={1}>
                <IconButton
                  className="socialIconBoxDrawbox"
                  onClick={() => window.open(" https://www.facebook.com/")}
                >
                  <FaFacebookF />
                </IconButton>
                <IconButton
                  className="socialIconBoxDrawbox"
                  onClick={() => window.open(" https://twitter.com/")}
                >
                  <FaTwitter />
                </IconButton>
                <IconButton
                  className="socialIconBoxDrawbox"
                  onClick={() => window.open(" https://www.instagram.com/")}
                >
                  <FaInstagram />
                </IconButton>
              </Box>
            </Box>
          </ul>
        </li>
      </ul>
    </Box>
  );
  return (
    <>
      <AppBar className="headerNav" position="relative" style={{ boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px !important" }}>
        <Container maxWidth="lg" className={containerHeight}>
          {mobileView ? displayMobile() : displayDesktop()}
          <Box style={{ display: "flex", alignItems: "center", boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px !important" }}>
            <Box style={{ display: "flex" }}>
              <IconButton onClick={() => setDialogOpen(true)}>
                <SearchIcon />
              </IconButton>
              {themeSeeting.settings.theme === "DARK" && (
                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    changeTheme("LIGHT");
                  }}
                >
                  <BsCircleHalf />
                </IconButton>
              )}
              {themeSeeting.settings.theme === "LIGHT" && (
                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    changeTheme("DARK");
                  }}
                >
                  <BsCircleHalf style={{ color: "#303030" }} />
                </IconButton>
              )}
            </Box>
            &nbsp;&nbsp;
            <Box className={classes.menuIcon}>
              <IconButton
                className={drawericon}
                {...{
                  edge: "start",
                  color: "inherit",
                  "aria-label": "menu",
                  "aria-haspopup": "true",
                  onClick: handleDrawerOpen,
                }}
              >
                <MenuIcon
                  width="60px"
                  height="60px"
                  style={{ color: "#0E52D5", fontSize: "35px" }}
                />
              </IconButton>
              <Drawer
                {...{
                  anchor: "right",
                  open: drawerOpen,
                  onClose: handleDrawerClose,
                }}
              >
                <div className={drawerContainer}>
                  <Box className={classes.moblesectionlogo}>
                    {themeSeeting.settings.theme === "DARK" ? (
                      <img
                        className={logoDrawer}
                        src="images/DarkLogo.png"
                        alt=""
                        style={{ width: "100px" }}
                      />
                    ) : (
                      <img
                        className={logoDrawer}
                        src="images/LightLogo.png"
                        alt=""
                        style={{ width: "100px" }}
                      />
                    )}

                    <Box className="iconsection">
                      <IconButton onClick={handleDrawerClose}>
                        <CgCloseO />
                      </IconButton>
                    </Box>
                  </Box>

                  <Box className={classes.avatarsection}>
                    {auth?.userLoggedIn ? (
                      <>
                        <Avatar
                          src={
                            auth?.userData?.profilePic
                              ? auth?.userData?.profilePic
                              : ""
                          }
                          style={{
                            width: "72px",
                            height: "72px",
                            borderRadius: "50%",
                            color: "#34495E",
                            background: "#D9D9D9",
                          }}
                        />
                        &nbsp;&nbsp;&nbsp;{" "}
                        <Box>
                          <Typography variant="h5">
                            {auth?.userLoggedIn && auth?.userData?.firstName ? (
                              <>
                                {auth?.userData?.firstName}&nbsp;
                                {auth?.userData?.lastName}
                              </>
                            ) : (
                              "..."
                            )}
                          </Typography>

                          {auth.userData.userType === "ADMIN" ? (
                            <Typography
                              variant="body1"
                              style={{
                                color: "#B2B0B0",
                                borderBottom: "1px solid #b2b0b0b0",
                                cursor: "pointer",
                              }}
                              onClick={() => history.push("/profile")}
                            >
                              Manage Your profile
                            </Typography>
                          ) : (<Typography
                            variant="body1"
                            style={{
                              color: "#B2B0B0",
                              borderBottom: "1px solid #b2b0b0b0",
                              cursor: "pointer",
                            }}
                            onClick={() => history.push("/user-profile")}
                          >
                            Manage Your profile
                          </Typography>)}

                        </Box>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => history.push("/login")}
                        >
                          Login
                        </Button>
                      </>
                    )}
                  </Box>

                  {!mobileView ? (
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                      className={classes.dessection}
                    >
                      {destopmenuText}
                    </Box>
                  ) : (
                    <>
                      <Box> {menuText}</Box>
                      <Box
                        className={classes.marginsec}
                        style={
                          !auth?.userLoggedIn ||
                            auth?.userData?.userType === "USER"
                            ? { marginTop: "125px" }
                            : {}
                        }
                      >
                        {destopmenuText}
                      </Box>
                    </>
                  )}
                </div>
              </Drawer>
            </Box>
          </Box>
        </Container>
      </AppBar>
      {dialogOpen && (
        <Paper>
          <Dialog
            fullWidth
            maxWidth="lg"
            className="searchdiaogBox"
            style={{
              position: "absolute",
              top: "10%",
              // minHeight: "695px",
            }}
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
          >
            <IconButton
              className="closeButton"
              onClick={() => setDialogOpen(false)}
            >
              <CloseIcon style={{ color: "#AAAAAA" }} />
            </IconButton>

            <DialogContent className={classes.DialogBox}>
              <SearchResultFound />
            </DialogContent>
          </Dialog>
        </Paper>
      )}
      <RndomAddImageModal
        openLoginAlrtModal={openRandomAddImage}
        OpenLoginAlrtModalHamdler={OpenRandomAddImageHandler}
        CloseLoginAlrtModal={CloseRandomAddImage}
      />
      <RandomLoginAlrtModal
        openLoginAlrtModal={openLoginAlrtModal}
        OpenLoginAlrtModalHamdler={OpenLoginAlrtModalHamdler}
        CloseLoginAlrtModal={CloseLoginAlrtModal}
      />
    </>
  );
}
