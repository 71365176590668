import React, { useState, useContext } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { BsCircleHalf, BsSearch } from "react-icons/bs";
import SettingsContext from "src/context/SettingsContext";
import { AuthContext } from "src/context/Auth";
import {
  AppBar,
  Toolbar,
  makeStyles,
  IconButton,
  Hidden,
  SvgIcon,
  Typography,
  Dialog,
  DialogContent,
  Box,
  Avatar,
  Button,
} from "@material-ui/core";
import {
  FaWallet,
  FaSignOutAlt,
  FaUserEdit,
  FaSignInAlt,
} from "react-icons/fa";
import { Menu as MenuIcon } from "react-feather";
import PerfectScrollbar from "react-perfect-scrollbar";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    height: 70,
    padding: "0 10px"
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  avatar: {
    cursor: "pointer",
    width: 40,
    height: 40,
    "@media (max-width: 767px)": {
      width: "30px",
      height: "30px",
    },
  },
  walletbox: {
    padding: "10px",
    "& h5": {
      fontSize: "14px",
    },
  },
  desktopDrawer: {
    position: "absolute",
    right: 0,
    top: 30,
    width: 280,
    background: "#5089F6",
  },
  userDetails: {
    "& h5": {
      color: theme.palette.primary.main,
      fontSize: "13px !important",
    },
    "& body2": {
      fontSize: "11px !important",
      lineHeight: "17px",
    },
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar
      elevation={0}
      className={clsx(classes.root, className)}
      style={{boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px"}}
      color="inherit"
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            color="#00e0b0"
            onClick={onMobileNavOpen}
            style={{ marginRight: 10 }}
          >
            <SvgIcon fontSize="small">
              <MenuIcon style={{ color: "rgb(90, 134, 255)" }} />
            </SvgIcon>
          </IconButton>
        </Hidden>

        <TopBarData />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;

export function TopBarData() {
  const classes = useStyles();
  const history = useHistory();
  const themeSeeting = useContext(SettingsContext);
  const auth = useContext(AuthContext);
  const changeTheme = (type) => {
    themeSeeting.saveSettings({
      theme: type,
    });
  };

  const sections = [
    {
      title: "Edit Profile",
      href: "/edit-profile",
      icon: FaUserEdit,
    },
    {
      title: "Profile",
      href: "/",
      icon: FaWallet,
    },

    {
      title: "Logout",
      href: "/dashboard",
      icon: FaSignOutAlt,
    },
  ];

  return (
    <>
      <Box flexGrow={1} />
      <Box pr={1}>
        {themeSeeting.settings.theme === "DARK" && (
          <IconButton
            variant="contained"
            color="primary"
            onClick={() => {
              changeTheme("LIGHT");
            }}
            style={{ fontSize: "31px" }}
          >
            <BsCircleHalf />
          </IconButton>
        )}
        {themeSeeting.settings.theme === "LIGHT" && (
          <IconButton
            variant="contained"
            color="primary"
            onClick={() => {
              changeTheme("DARK");
            }}
            style={{ fontSize: "31px" }}
          >
            <BsCircleHalf style={{ color: "#303030" }} />
          </IconButton>
        )}
      </Box>
      <Box className={classes.userDetails}>
        <Typography variant="h5">
          {`${auth?.userData?.firstName}` + " " + `${auth?.userData?.lastName}`}
        </Typography>
        <Typography
          variant="body2"
          style={{
            fontSize: "12px",
            lineHeight: "20px",
          }}
        >{`${auth?.userData?.email}`}</Typography>
      </Box>
      &nbsp; &nbsp;&nbsp;
      <Box>
        <Avatar
          src={
            auth?.userData?.profilePic ? `${auth?.userData?.profilePic}` : " "
          }
          className={classes.avatar}
          onClick={() => history.push("/profile")}
        />
      </Box>
    </>
  );
}
