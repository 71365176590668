import React, { useEffect, useState, useContext } from "react";
import {
  makeStyles,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { AuthContext } from "../context/Auth";
const useStyles = makeStyles((theme) => ({
  closeButton: { position: "absolute", right: "0", top: "-7px", zIndex: "1" },
}));

function RandomLoginAlrtModal({
  openLoginAlrtModal,
  CloseLoginAlrtModal,
  OpenLoginAlrtModalHamdler,
  requiretimeToClose,
}) {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(18);

  useEffect(() => {
    if (seconds == 0) {
      CloseLoginAlrtModal();
    }
  }, [seconds]);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });
  
  return (
    <>
      <Dialog
        open={openLoginAlrtModal}
        // onClose={CloseLoginAlrtModal}
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography
            variant="body1"
            style={{ textAlign: "center", fontSize: "16px" }}
          >
            Ad closes in - 00:00:{seconds}
          </Typography>

          <IconButton
            aria-label="IconButtonclose"
            className={classes.closeButton}
            onClick={CloseLoginAlrtModal}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <a href={auth.advertisement?.POPUP?.url} target="_blank">
          <img
            src={auth.advertisement?.POPUP?.image}
            alt=""
            style={{ maxWidth: "100%", width: "auto" }}
          />
        </a>
      </Dialog>
    </>
  );
}

export default RandomLoginAlrtModal;
