import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { getDataHandlerAPI } from "src/apiConfig/service";
import { calculateTimeLeft } from "../utils/index";
import Apiconfigs from "src/apiConfig/config";
import { socketURL } from "src/apiConfig/config";

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    sessionStorage.setItem("token", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    sessionStorage.removeItem("token");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.sessionStorage.getItem("token");
 
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData, setUserData] = useState({});
  const [postImageData, setPostImageData] = useState([]);
  const [postVideoData, setPostVideoData] = useState([]);
  const [advertisement, setadvertisement] = useState({});
  const [scheduledList, setscheduledList] = useState([]);
  const [handleScrollMatch, sethandleScrollMatch] = useState([]);
  const [categoryType, setcategoryType] = useState("");

  const [endTime, setEndtime] = useState();
  const [timeLeft, setTimeLeft] = useState();
  const [isSocketOn, setisSocketOn] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  useEffect(() => {
    const web = new WebSocket(socketURL);
    try {
      let socketData = {
        // requestType: "WorldCup",
        requestType: "LiveMatch",
      };

      web.onopen = () => {
        web.send(JSON.stringify(socketData));
        web.onmessage = async (event) => {
      
          if (event.data !== "[object Promise]" && event.data !== "null") {
            let obj = JSON.parse(event.data);
            setisSocketOn(true);
      
            if (obj.responseResult && obj.responseResult.length > 0) {
              sethandleScrollMatch(obj.responseResult);
              // setIsLoading(false);
            }
          } else {
            sethandleScrollMatch([]);
          }
        };
      };
    } catch (err) {
      // setIsLoading(false)
    }
    // }
    return () => {
      web.close();
    };
  }, []);
  const getscheduledList = async (token) => {
    try {
      const scheduledList = await getDataHandlerAPI("scheduledList", token);
      if (scheduledList) {
        setscheduledList(scheduledList.data.result.docs);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getscheduledList(window.sessionStorage.getItem("token"));
  }, []);
  const getUserProfileDatahandler = async (token) => {
    try {
      const res = await axios({
        method: "GET",
        url: Apiconfigs.profile,
        headers: {
          token: token,
        },
      });
      if (res.data.statusCode === 200) {
        setUserData(res?.data?.result);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        if (error.response.data.statusCode == "404") {
         
          window.sessionStorage.removeItem("token");
        }
      }
    }
  };
  const getPostList = async (token, id) => {
    try {
      // const responseFeed = await getDataHandlerAPI("feed", token);

      const res = await axios({
        method: "GET",
        url: Apiconfigs.feedblogList,
        headers: {
          token: token,
        },
        params: {
          categoryType: id,
        },
      });
      if (res.data.statusCode === 200) {
        
        setIsLoader(false);
        setPostImageData(
          res?.data?.result.docs.filter((data) => {
            return data.mediaType != "Video";
          })
        );
        setPostVideoData(
          res?.data?.result.docs.filter((data) => {
            console.log(data.mediaType,"test")
            return data.mediaType == "Video";
          })
        );
      }
    } catch (error) {
      setIsLoader(false);
      console.log(error);
      setPostImageData([]);
      setPostVideoData([]);
    }
  };
  const getPostListresponse = async (token) => {
    try {
      const response = await getDataHandlerAPI("getAllfeeds", token);
      if (response) {
        // setUserData(response?.data?.result);
        let data = response?.data?.result;
        let LEADERBOARD = data?.advertisement?.docs?.find((data) => {
          return data?.advertisementType == "LEADERBOARD" && data?.isActive;
        });
        let MREC = data?.advertisement?.docs?.find((data) => {
          return data?.advertisementType == "MREC" && data?.isActive;
        });
        let SKYSCRAPER = data?.advertisement?.docs?.find((data) => {
          return data?.advertisementType == "SKYSCRAPER" && data?.isActive;
        });
        let POPUP = data?.advertisement?.docs?.find((data) => {
          return data?.advertisementType == "POPUP" && data?.isActive;
        });
        let corousel = data.corousel;

      
        let obj = {
          LEADERBOARD,
          MREC,
          SKYSCRAPER,
          POPUP,
          corousel,
        };
        setadvertisement(obj);
        // console.log("===  obj obj ", obj);
        // if(obj){

        //   setadvertisement(obj);
        // }else{
        //   setadvertisement("")
        // }
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (categoryType == "") {
      getPostList(window.sessionStorage.getItem("token"));
      setIsLoader(true);
    }
    getPostListresponse(window.sessionStorage.getItem("token"));
  }, []);

 
  useEffect(() => {
    if (categoryType != "") {
      getPostList(window.sessionStorage.getItem("token"), categoryType);
    }
   
  }, [categoryType]);
  useEffect(() => {
    if (endTime) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(endTime * 1000));
      }, 5000);
      return () => clearTimeout(timer);
    }
  });
  useEffect(() => {
    if (window.sessionStorage.getItem("token")) {
      getUserProfileDatahandler(window.sessionStorage.getItem("token"));
    }
  }, []);

  let data = {
    userLoggedIn: isLogin,
    userData,
    postImageData,
    postVideoData,
    advertisement,
    scheduledList,
    handleScrollMatch,
    categoryType,
    setcategoryType,
    timeLeft,
    setTimeLeft,
    setEndtime,
    setIsLogin: (data) => setIsLogin(data),
    checkLogin: (data) => checkLogin(data),
    getUserProfileDatahandler: (data) => getUserProfileDatahandler(data),
    getPostList: (data) => getPostList(data),
    isLoader,
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
