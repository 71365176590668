import React from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Grid,
  Box,
  Typography,
  Container,
} from "@material-ui/core";
import Logo from "src/component/Logo";
import { useHistory } from "react-router-dom";
import TopHeaderSection from "../HomeLayout/TopHeaderSection";

const useStyles = makeStyles((theme) => ({
  headbox: {
    backgroundColor: theme.palette.background.default,
    margin: "0",
    padding: "0",
  },
  content: {
    height: "calc(100vh - 47px)",
    margin: "0",
    padding: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  imgbox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 47px)",
    backgroundSize: "cover",
    backgroundPosition: "center",
    background: "url('images/login_bg.png')",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    
  },
  LogoIcon: {
    cursor: "pointer",
    width: "150px",
  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <>
      <Box className={classes.headbox}>
        <Box>
          <TopHeaderSection />
        </Box>
        <Grid container spacing={0} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={6} >
            <Box className={classes.imgbox}>
              <Box className={classes.LogoIcon} onClick={() => history.push("/")}>
                <img src="images/DarkLogo.png"  width= "100%" alt="Logo Image"/>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Container maxWidth="sm">
              <Box className={classes.content}>
                <Box style={{ width: "100%" }}>{children}</Box>
              </Box>
            </Container>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node,
};

export default LoginLayout;
