import React, { useEffect } from "react";
import {
  makeStyles,
  Box,
  Typography,
  Grid,
  Paper,
  IconButton,
} from "@material-ui/core";
import { FaCalendar } from "react-icons/fa";
import { BiTime } from "react-icons/bi";
import moment from "moment";
import { useHistory } from "react-router-dom";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
    "& h5": {
      color: theme.palette.primary.main,
      fontSize: "14px",
      paddingtop: "5px",
      marginTop: "10px",
    },
    "& p": {
      color: theme.palette.secondary.default,
      fontSize: "13px",
    },
    // },FeaturedVideoSection: {
    //   paddingBottom: "10px",
    //   position: "relative",
    "& .playButton": { 
      backgroundColor: "whitesmoke !important",
      left: "51% !important",
      top: "50% !important",
      zIndex: "999",
      position: "absolute !important",
      transform: "translate(-50%, -50%) !important",
      "& svg": {
        color: "#000",
      },
    },
  },
}));

function HorseRacingCard({ data }) {
  const classes = useStyles();
  const history = useHistory();
  const updateDimensions = () => {
    var offsetWidth = document.getElementById(
      "imagecard" + data?._id
    )?.offsetWidth;
    if (offsetWidth) {
      var newoofsetWidth = offsetWidth - 60;
      document.getElementById("imagecard" + data?._id).style.height =
        newoofsetWidth + "px";
    }
  };
  useEffect(() => {
    updateDimensions();
  }, [data, data?._id]);

  return (
    <Paper className={classes.root} elevation={0} onClick={() =>
      history.push({
        pathname: "/news-details",
        search: data._id,
      })
    }
    >
      <Box>
        {data?.mediaType == "Image" ? (
          <Box style={{ position: "relative" }}>
            <figure className="scaleImageClass">
              <img src={data?.thumbnail} />
            </figure>
          </Box>
        ) : (
          <Box style={{ position: "relative" }}>
            <IconButton className="playButton ">
              <PlayArrowIcon />
            </IconButton>
            <figure className="scaleImageClass">
              <img src={data?.thumbnail} />
            </figure>
          </Box>
        )}
      </Box>
      <Typography variant="h5">{data?.headline}</Typography>
      <Typography variant="body1">
        <FaCalendar />
        &nbsp;
        <span>{moment(data?.createdAt).format("lll")}</span>
        &nbsp;
        <BiTime />
        &nbsp; {moment(data?.endDate).format("lll")}
      </Typography>
    </Paper>
  );
}

export default HorseRacingCard;
