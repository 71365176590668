import React from 'react'
import { makeStyles, Box, Typography, Dialog, DialogTitle, Button } from '@material-ui/core';
import Logo from './Logo';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "25px",
        [theme.breakpoints.down("xs")]: {
            padding: "15px"
        }
    }
}));

function RandomLoginAlrtModal({
    openLoginAlrtModal,
    CloseLoginAlrtModal,
    OpenLoginAlrtModalHamdler
}) {
    const classes = useStyles()
    const history = useHistory();
    return (
        <>
            <Dialog
                open={openLoginAlrtModal}
                onClose={CloseLoginAlrtModal}
                maxWidth="xs"
            >
                <Box className={classes.root}>
                    <Box align="center"><Logo /></Box>
                    <Box my={2} align="center">
                        <Typography variant='h4'>Welcome to 12thKhiladi</Typography>
                    </Box>
                    <Box my={2} align="center">
                        <Typography variant='h5'>Login for better experience</Typography>
                    </Box>
                    <Box align="center" my={2}>
                        <Button variant="contained" color="primary" onClick={() => history.push("/login")} style={{ marginRight: "8px" }}>
                            Login
                        </Button>
                        <Button variant="contained" color="primary" onClick={() => history.push("sign-up")} style={{ marginLeft: "8px" }}>
                            SignUp
                        </Button>
                    </Box>
                </Box>
            </Dialog>
        </>
    )
}

export default RandomLoginAlrtModal;